@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  .bg1-img {
    /* background-image: url('./images/bg_1.jpeg'); */
    background-size: cover;
    height: 250px;
  }
  .disabled-btn {
    /* opacity: 0.9; */
    pointer-events: none;
  }
}

.react-calendar {
  border: 1px solid #eee;
  /* border-radius: 10px; */
  /* padding: 1em; */
  width: min(100%, 700px);
  margin: auto;

  /* margin: auto; */
  font-family: sans-serif !important;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
  background-color: #4287f5;
  color: white;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  /* margin-bottom: 1em; */
  color: white;
  background: #4287f5;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #4386f4;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #417cda;
  opacity: 0.9;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.75em;
  background-color: #4287f5;
  color: white;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekdays__weekday--weekend {
  color: #000000;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  border: 0.2px solid #eee;
  color: #000000;
  background-color: #eee;
}

.react-calendar__month-view__days__day--weekend:disabled {
  color: #757575;
  background-color: #D3D3D3 !important;
}
.react-calendar__month-view__days__day--weekend:enabled:hover {
  color: #000000;
  background-color: #4287f5 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  border: 0.2px solid #eee;
  padding: 1em;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  color: #757575;
  background-color: #D3D3D3;
  border: 1px solid #eee !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  opacity: 0.9;
}
/* .react-calendar__tile--now {
  background: #4287f5;
} */
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  opacity: 0.9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  opacity: 0.9;
}
.react-calendar__tile--active {
  background: #4287f5;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #4287f5;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.bold {
  font-weight: 500;
}

.NewTime {
  margin-top: 10px;
}

/* .buttons{
  margin: auto;
  padding: auto;
} */

.slots {
  /* justify-content: space-around; */
  margin: 10px;
}

.weekends{
  text-align: center;
  font-size: 14px;
  margin-top: 8px;
  color: #606060;
}


.card {
  background-color: #FFFFFF;
  width: 80%;
  max-width: 800px;
  min-height: 500px; /* Adjust this value as desired */
  margin: 40px auto;
  padding: 30px;
  box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}


#reschedule-btn {
  background-color: #d0e9c6;
  color: #333;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  padding: 10px 15px;
  cursor: not-allowed;
}

#reschedule-btn:disabled {
  background-color: #d0e9c6;
  color: #333;
}

#reschedule-btn:not(:disabled) {
  background-color: #2e8b57;
  color: #fff;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 90%;
  margin-bottom: 10px;
}

table,
th,
td {
  border: 1px solid black;
  text-align: center;
  margin: 2px;
  padding: 10px;
}

.table-container {
  margin: auto;
  padding: auto;
}

.checkbox-container {
  margin-left: 3%;
  margin-top: 25px;
}

.checkbox-label {
  font-weight: bold;
  color: black;
}

.button-container {
  text-align: center;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: bold; 
  text-align: justify;
  margin-bottom: 1rem;
}
